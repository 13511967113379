import { useLogto } from "@logto/react";
import type { ReactNode } from "react";
import { useContext, useEffect } from "react";
import ImpersonationContext from "../contexts/ImpersonationContext";
import type { User } from "../contexts/UserContext";
import { useLazyBackendRequest } from "../hooks/useBackendRequest";
import { useUser } from "../hooks/useUser";
import LoginPage from "../pages/LoginPage";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, error } = useLogto();
  const { organization } = useContext(ImpersonationContext);
  const { user, setUser } = useUser();
  const { request: fetchUser } = useLazyBackendRequest<User>(`/users/me`);

  async function populateUserContext() {
    const response = await fetchUser();
    if (response.data != null) {
      setUser(response.data);
    } else {
      console.error("Could not fetch user data");
    }
  }

  useEffect(() => {
    if (isAuthenticated && user == null) {
      populateUserContext();
    }
  }, [isAuthenticated, setUser]);

  useEffect(() => {
    if (user != null) {
      populateUserContext();
    }
  }, [organization]);

  if (!isAuthenticated || error) {
    return <LoginPage redirectUrl={location.pathname} />;
  }

  return <>{children}</>;
}
